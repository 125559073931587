<template>
  <view-homepage />
</template>

<script>
import { useStore } from 'vuex';
import { getMetadataAndTags } from '../helpers/metadata';
import ViewHomepage from '../components/ViewHomepage.vue';
import { clientWorkWindowEvent } from '../helpers/event-dispatch-helpers';
export default {
  name: 'Home',
  components: {
    ViewHomepage,
  },
  setup() {
    const store = useStore();
    const metadata = getMetadataAndTags(store.state.settings.metadata, 'homepage', 'Home');
    useHead({
      ...metadata.metaTitle,
      meta: metadata.metaTags,
    });

    definePageMeta({
      layout: 'default',
    });
    onMounted(() => {
      if (useRuntimeConfig().public.IS_INTRANET) {
        setPageLayout('intranet');
      }
    });
  },
  mounted() {
    clientWorkWindowEvent('homepage');
  },
};
</script>
